.container_block_text_one {
  background-color: #221e1d;
  position: relative;
}

.text_block_one,
.text_block_one_typeTwo {
  color: #ffff;
  width: 90%;
  padding-top: 70px;
}

.text_block_one {
  font-size: 20px;
}

.text_block_one_typeTwo {
  font-size: 12px;
}

@media (min-width: 1024px) {
  .text_block_one {
    font-size: 25px;
  }

  .text_block_one_typeTwo {
    font-size: 20px;
  }
}

.container_block_text_one span {
  color: #ef7a90;
}

.button_block_one {
  text-align: end;
  padding-top: 25px;
  padding-bottom: 40px;
}

.button_block_one button {
  cursor: pointer;
}

.line_block_one {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 8px;
  background: transparent linear-gradient(180deg, #ef7a90 0%, #24748a 100%) 0% 0% no-repeat padding-box;
}

@media (min-width: 1024px) {
  .size_desktop_blok_one {
    width: 80%;
    margin: 0 auto;
  }
}
