@import 'react-alice-carousel/lib/alice-carousel.css';

#servicios .about {
  margin: auto;
  padding-top: 50%;
  opacity: 0;
  animation: about 1s forwards 0.6s;

  font-size: 24px;
  font-weight: 600;
}

#servicios .about_fixed {
  display: block;
  transform: rotateZ(90deg);
  position: fixed;
  right: 0;
  top: 85%;
  font-size: 10px;
  transition: 0.5s all;
  z-index: 1000;
}

.servicios_header {
  position: relative;
  padding-bottom: 100px;
}

.servicios_header h2 {
  margin-top: 100px;
  font-size: 60px;
  text-align: center;
  font-weight: 600;
}

.servicios_header .flex_container {
  display: flex;
}

#servicio .flex_container p {
  width: 90%;
}

.change_white_color {
  color: white;
}

#servicios .about span {
  display: block;
  padding-left: 60px;
  padding-right: 50px;
  max-width: 500px;
  line-height: 1;
}

#servicios .about span p {
  color: #ef7a90;
  font-size: 40px;
}

#servicios .about hr {
  margin: 20px 0;
  background: black;
  width: 0%;
  height: 1px;
  border: none;
  opacity: 0;
  animation: aboutHr 1.8s forwards 0.5s;
}

@keyframes aboutHr {
  to {
    width: 100%;
    opacity: 1;
  }
}

@keyframes about {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

#servicios .flecha_abajo {
  width: 100%;
  text-align: center;
  opacity: 0;
  animation: fadeIn 1s forwards 3s;
  position: absolute;
  bottom: 15%;
}

#servicios .flecha_abajo img {
  width: 10%;
  animation: flecha_abajo_img 4s forwards 3s;
  max-width: 30px;
}

#servicios .button_container {
  margin-top: 25px;
  display: flex;
  flex-direction: row-reverse;
  position: absolute;
  bottom: 5%;
  right: 5%;
}

#servicios .button_container a {
  text-decoration: none;
  color: black;
  border: 1px solid #ef7a90;
  border-radius: 100px;
  padding: 5px 20px;
  display: block;
  text-align: center;
  width: fit-content;
}

#servicios .galeria h2 {
  text-align: right;
  font-size: 25px;
  padding: 0 0 100px 0;
  color: #221e1d;
  font-weight: 600;
}

.manifesto {
  background: #000000;
  color: #fff;
  position: relative;
  margin-top: 100px;
}

.linea_color {
  position: absolute;
  width: 0.6%;
  left: 0;
  right: 0.5%;
  top: 2%;
  margin: 0 auto;
  height: 200px;
  transition: 4s all;
  background: linear-gradient(180deg, rgba(33, 117, 139, 1) 0%, rgba(236, 122, 144, 1) 100%) !important;
}

.linea_color_top_5 {
  top: 7%;
}

.linea_color_top_9 {
  top: 11%;
}

.manifesto .indio {
  position: absolute;
  top: 30px;
  left: 5%;
  padding: 1px 5px;
  border-radius: 20px;
  width: 20%;
  max-width: 80px;
  text-align: center;
  font-size: 12px;
}

@media (min-width: 1024px) {
  .manifesto .indio {
    left: 15%;
    top: 100px;
  }
}

.manifesto .border-gradient {
  border: 10px solid;
  border-image-slice: 1;
  border-width: 1px;
}

#servicios .manifesto li {
  padding-bottom: 5px;
  list-style: none;
}

.manifesto .title_manifesto {
  display: flex;
  margin: 15px 0;
}

.title_manifesto div:nth-child(1) {
  font-size: 12px;
  color: #ef7a90;
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title_manifesto div:nth-child(2) {
  font-size: 13px;

  font-weight: 600;
}

.desc_manifesto {
  font-size: 13px;
  padding-left: 20%;
  padding-right: 10%;
  font-weight: 300;
}

#servicios .content_manifesto {
  padding-top: 100px;
  padding-bottom: 100px;
  position: relative;
}

#servicios .content_manifesto h2 {
  font-size: 2.5rem;
  padding-bottom: 10px;
  font-weight: 600;
  text-align: left;
  font-style: italic;
  color: #ef7a90;
}

#servicios .pencil {
  position: absolute;
  right: 0;
  top: 8%;
  width: 20px;
}

#servicios .content_manifesto p {
  padding-left: 60px;
  padding-right: 30px;
  width: 80%;
  padding-bottom: 20px;
}

#servicios .content_manifesto img {
  display: none;
}

@media only screen and (min-width: 768px) {
  #servicios .about {
    padding-top: 200px;
  }

  .servicios_header h2 {
    font-size: 160px;
  }

  .servicios_header .flex_container {
    width: 100%;
    flex-direction: row-reverse;
  }
  .servicios_header .flex_container p {
    width: 50%;
  }

  #servicios .about_fixed {
    right: 2%;
    font-size: 13px;
  }

  #servicios .about span {
    display: block;
    padding-left: 0px;
    padding-right: 0px;
    width: 60%;
    font-size: 40px;
    margin: 0px auto;
  }

  #servicios .content_manifesto h2 {
    font-size: 3rem;
  }

  .title_manifesto div:nth-child(1) {
    font-size: 17px;
  }

  .title_manifesto div:nth-child(2) {
    font-size: 20px;
  }

  .desc_manifesto {
    font-size: 20px;
  }

  #servicios .content_manifesto img {
    display: block;
    width: 50px;
    margin-bottom: 25px;
  }
  #servicios .title_manifesto {
  }

  #servicios .content_manifesto .pencil {
    width: 30px;
  }

  #servicios .content_manifesto ul {
    width: 60%;
    float: right;
    display: flex;
    align-items: flex-end;
  }

  .linea_color {
    width: 0.2%;
    height: 350px;
  }
}
.bg_black_all {
  background-color: #000000;
  margin-top: 100px;
}


.w_slash_p{
  width: 30px;
}

.flex_title_projects{
  display: flex;
  gap: 10px;
}