.container_all {
  background-color: #000000;
  overflow: hidden;
  position: relative;
}

.title_for {
  color: white;
  font-size: 20px;
}

@media (min-width: 1024px) {
  .title_for {
    font-size: 40px;
  }
}

.container_slider {
  display: flex;
  margin-top: 50px;
  padding-bottom: 60px;
  gap: 5px;
  overflow-x: auto;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

@media (min-width: 1024px) {
  .container_slider {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    overflow-x: hidden;
    gap: 10px;
  }
}

.container_img {
  background-color: #221e1d;
  border: 1px white solid;
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container_img img {
  width: 100px;
  object-fit: contain;
}

@media (min-width: 1024px) {
  .container_img {
    width: 99%;
    height: 200px;
  }
}

.text_who {
  color: #ffff;
  font-size: 11px;
  text-align: center;
  width: 80%;
  margin: 0 auto;
  padding-top: 5px;
}
@media (min-width: 1024px) {
  .text_who {
    font-size: 16px;
  }
}

.figure_size_for {
  width: 50px;
}

@media (min-width: 1024px) {
  .figure_size_for {
    width: 60px;
  }

  .paragraph_for {
    font-size: 16px;
    width: 70%;
  }
}

.line_decoration_forwho {
  position: absolute;
  height: 10px;
  width: 100%;
  bottom: 0;
  background: transparent linear-gradient(90deg, #ef7a90 0%, #24748a 100%) 0% 0% no-repeat padding-box;
}
