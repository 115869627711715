.container_our_projects {
  padding: 100px 0px;
}

.title_our_projects {
  font-size: 20px;
  margin-bottom: 50px;
  font-weight: 600;
}

@media (min-width: 1024px) {
  .title_our_projects {
    font-size: 40px;
  }
}

/* //////////////////////SLIDER ONE////////////// */

.swiper_slider_one {
  position: relative;
  width: 100%;
  height: 100%;
  padding-bottom: 10px;
}

.contet_slider_one {
  background-color: #221e1d;
  padding: 10px;
  width: 90%;
}

@media (min-width: 1024px) {
  .contet_slider_one {
    width: 93%;
  }
}

.decoration_border_slider_one {
  position: absolute;
  top: 20px;
  right: -0px;
  width: 95%;
  height: 95%;
  z-index: -1;
  background: transparent linear-gradient(49deg, #ef7a90 0%, #24748a 100%) 0% 0% no-repeat padding-box;
}

.info_slider_one {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.contet_slider_one img {
  width: 100%;
}

.info_slider_one p {
  font-size: 12px;
  font-weight: 500;
  max-width: 60%;
  color: white;
}

@media (min-width: 1024px) {
  .info_slider_one p {
    font-size: 18px;
  }
}

.info_slider_one img {
  width: 80px;
  height: 80px;
  object-fit: contain;
}

.space_no_img {
  width: 80px;
  height: 80px;
}

@media (min-width: 1024px) {
  .info_slider_one img {
    width: 100px;
    height: 100px;
  }

  .space_no_img {
    width: 100px;
    height: 100px;
  }
}

.arrows_slider_one {
  display: flex;
  justify-content: end;
  align-items: start;
  margin-top: 40px;
  gap: 50px;
}

.arrows_slider_one div:first-child {
  transform: rotate(180deg);
  position: relative;
  top: -5px;
}

.arrows_slider_one div:nth-child(2) img,
.arrows_slider_one div:first-child img {
  width: 40px;
  cursor: pointer;
}

.swiper-wrapper {
  align-items: flex-start;
}

/* //////////////////////SLIDER two////////////// */

.img_slider_two img {
  width: 100%;
  object-fit: cover;
}

.swiper_slider_two {
  position: relative;
  width: 100%;
  height: 100%;
  padding-bottom: 20px;
}

@media (min-width: 1024px) {
  .swiper_slider_two {
    padding-bottom: 30px;
  }
}
