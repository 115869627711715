.title_testimonies {
  font-size: 20px;
  margin-top: 70px;
  margin-bottom: 33px;
}

@media (min-width: 1024px) {
  .title_testimonies {
    font-size: 40px;
    margin-top: 100px;
  }
}

.testimonies_container {
  width: 90%;
  position: relative;
  margin-top: 70px;
}

.content_wrapper {
  background-color: #faf8f8;
  border: 1px black solid;
  padding: 13px;
  position: relative;
  z-index: 10;
}

.img_logo_testimonies {
  width: 100%;
  display: flex;
  justify-content: end;
  margin-top: 10px;
}

.img_logo_testimonies img {
  width: 60px;
  height: 60px;
  object-fit: contain;
}

.testimonies_container::after {
  content: "";
  position: absolute;
  top: -10%;
  right: -10%;
  width: 100%;
  height: 100%;
  z-index: 1;
  border: 1px black solid;
  background: transparent linear-gradient(180deg, #24748a 0%, #ef7a90 100%) 0% 0% no-repeat padding-box;
}

.testimonies_container::before {
  content: "";
  position: absolute;
  z-index: 2;
  top: -5%;
  right: -5%;
  width: 100%;
  border: 1px black solid;
  height: 100%;
  background: transparent linear-gradient(0deg, #24748a 0%, #ef7a90 100%) 0% 0% no-repeat padding-box;
}

.title_testimonie {
  color: #221e1d;
  font-weight: bold;
  font-size: 13px;
}

.rol_testimonie {
  color: #ef7a90;
  font-size: 10px;
}

.paragrapgh_testimonie {
  margin-top: 10px;
  font-size: 10px;
  height: 130px;
  overflow: auto;
}

.container_arrows {
  display: flex;
  margin-top: 20px;
  justify-content: end;
  gap: 77px;
  margin-bottom: 73px;
}

.container_arrows img {
  width: 40px;
}

.container_arrows :nth-child(1) {
  transform: rotate(180deg);
}

/* DESTOCK */
/* /////////////////////////////// */

.testimonies_container_destock {
  position: relative;
}

.testimonies_content_destock {
  background-color: #faf8f8;
  border: 1px black solid;
  padding: 25px;
  max-width: 295px;
}



.testimonies_content_destock :nth-child(1) {
  font-size: 15px;
  font-weight: 700;
}

.testimonies_content_destock :nth-child(2) {
  color: #ef7a90;
  font-size: 15px;
  font-weight: 600;
  height: 40px;
}

.testimonies_content_destock :nth-child(3) {
  font-size: 11px;
  margin-top: 15px;
  height: 140px;
  overflow: auto;
}

.img_logo_testimonies_destok {
  display: flex;
  justify-content: end;
}

.img_logo_testimonies_destok img {
  width: 80px;
  height: 80px;
  object-fit: contain;
}

.line_bottom_testimonies {
  position: absolute;
  width: 100%;
  height: 5px;
  bottom: 0;
  left: 0;
  background: transparent linear-gradient(90deg, #ef7a90 0%, #24748a 100%) 0% 0% no-repeat padding-box !important;
}

.swiper_testimonies {
  margin-bottom: 100px;
  width: 100%;
}

.arrow_hide {
  width: 50px;
}
