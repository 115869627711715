@import "react-slideshow-image/dist/styles.css";

.slide-container {
  border: 1px solid #221e1d;
  width: 100%;
  max-width: 500px;
}

.each-fade {
  padding: 20px;
  position: relative;
  height: 300px;
}

.slide-container .each-fade > div {
  width: 75%;
}

.each-fade > div img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.each-fade p {
  width: 100%;
  font-size: 1em;
  margin: 0;
}

.autoplay-buttons {
  width: 100%;
  text-align: center;
}
.autoplay-buttons button {
  background: none;
  border: none;
  width: 16%;
  margin: 15px 10px;
  max-width: 60px;
}

.autoplay-buttons button img {
  width: 100%;
  cursor: pointer;
}

.autoplay-buttons button:focus {
  outline: none !important;
}

/* galeria en servicios */
.galeria {
  left: 0;
}

#servicios .galeria,
#servicios .galeria {
  margin: auto;
  position: relative;
  top: 0px;
  width: 80%;
  font-size: 12px;
}

@keyframes galeria {
  0% {
    top: -300px;
  }
  100% {
    top: 0px;
  }
}

#servicios .galeria .servicios span {
  display: block;
  font-weight: 600;
  padding-bottom: 5px;
  font-size: 16px;
}

#servicios .galeria .servicios hr {
  margin-bottom: 30px;
  border: 0.5px solid black;
  height: 0px;
  margin-top: 10px;
}

#servicios .galeria .servicios li {
  list-style: none;
}

.icons_active {
  display: flex;
  width: 60%;
  margin: 0px auto;
  border: 1px solid black;
  padding: 5px 10px;
  margin-top: 25px;
  margin-bottom: 15px;
  position: relative;
  justify-content: space-between;
}

.icons_active div {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25%;
  transition: 2s all;
  z-index: 2000;
}

.icons_active img {
  max-height: 25px;
  cursor: pointer;
  transition: 2s all;
  opacity: 0.5;
}

.scale {
  animation: scale_img 4s forwards;
}

@keyframes scale_img {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.6;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.circulo_fondo {
  position: absolute !important;
  background: rgb(33, 117, 139) !important;
  background: linear-gradient(180deg, rgba(33, 117, 139, 1) 0%, rgba(236, 122, 144, 1) 100%) !important;
  width: 20% !important;
  height: 100%;
  z-index: 1000 !important;
  border-radius: 30px;
  opacity: 1;
  padding: 0 !important;
  top: 0;
}

.left_3 {
  left: 6%;
}

.left_26 {
  left: 30%;
}

/* .left_49 {
  left: 80px;
} */

.left_72 {
  left: 52%;
}

.left_100 {
  left: 75%;
}

.linea_progreso {
  width: 35%;
  /* background: rgb(33,117,139) !important; */
  background: linear-gradient(90deg, rgba(33, 117, 139, 1) 0%, rgba(236, 122, 144, 1) 100%);
  height: 3px;
  transition: 1s all;
}

.width_25 {
  width: 35% !important;
}

.width_50 {
  width: 50% !important;
}

.width_75 {
  width: 75% !important;
}

.width_100 {
  width: 100% !important;
}

@media only screen and (min-width: 768px) {
  .galeria_contenedor {
    display: flex;
    justify-content: space-between;
  }

  #servicios .galeria .servicios span {
    font-size: 25px;
  }

  #servicios .galeria .servicios li {
    font-size: 18px;
  }

  .each-fade {
    padding: 40px 50px;
  }

  .slide-container {
    width: 60%;
  }

  .galeria_contenedor .icons_active {
    order: -1;
    padding: 0;
    display: block;
    width: 10%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: 0;
    max-width: 60px;
  }

  .icons_active div {
    justify-content: normal;
    width: auto;
    text-align: center;
    display: block;
  }

  .icons_active img {
    padding: 0 5px;
  }

  .galeria_contenedor .autoplay-buttons {
    order: -1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 30%;
  }

  .galeria_contenedor .autoplay-buttons button {
    display: block;
    margin: 0px auto;
    text-align: center;
    padding: 15px 0;
    width: 30%;
  }

  .galeria_contenedor .autoplay-buttons button img {
    width: 100%;
    transform: rotate(90deg);
  }

  .circulo_fondo {
    width: 100% !important;
    height: 16%;
    left: 0;
    top: 0;
  }

  .linea_progreso {
    height: 6px;
  }

  .left_3 {
    top: 4%;
  }

  .left_26 {
    top: 28%;
  }

  /* .left_49 {
    top: 52%;
  } */

  .left_72 {
    top: 53%;
  }

  .left_100 {
    top: 78%;
  }
}

@media only screen and (min-width: 1024px) {
  .content_manifesto h2 {
    font-size: 11rem;
  }
}
