.container_all_work {
  background-color: black;
}

.container_info_work {
  padding-bottom: 100px;
}

.title_work {
  font-size: 24px;
  color: #ef7a90;
  max-width: 220px;
}

.container_title_work {
  padding-top: 67px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (min-width: 1024px) {
  .title_work {
    font-size: 40px;
    max-width: unset;
    font-weight: 600;
    margin-bottom: 60px;
  }
}

.figure_work {
  width: 20px;
}

.title_info_work {
  display: flex;
  align-items: start;
  gap: 20px;
  margin: 40px 0px;
}

.flex_info_two {
  width: 100%;
}

.flex_info_two :nth-child(1) {
  color: #ffffff;
  font-size: 13px;
  font-weight: 600;
}

.title_info_work p {
  color: #ef7a90;
  font-size: 12px;
}

.paragraph_work p {
  color: #ffffff;
  font-size: 11px !important;
  margin-top: 10px;
  font-weight: 500 !important;
}

@media (min-width: 1024px) {
  .flex_info_two :nth-child(1) {
    font-size: 18px;
  }
  .title_info_work p {
    font-size: 18px;
  }
  .paragraph_work p {
    font-size: 16px !important;
    margin-top: 5px;
    width: 70%;
  }

  .paragraph_work {
    display: flex;
    justify-content: end;
  }

  .info_work {
    padding-bottom: 80px;
  }
}

.line_decoration_work_desktop {
  width: 50%;
  height: 3px;
  margin-top: 10px;
  margin-bottom: 30px;
  background: transparent linear-gradient(90deg, #ef7a90 0%, #24748a 100%) 0% 0% no-repeat padding-box;
}
