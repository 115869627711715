.line_color {
  position: absolute;
  width: 4px;
  left: 0;
  right: 0.5%;
  top: 55px;
  margin: 0 auto;
  height: 250px;
  border-radius: 10px;
  transition: 4s all;
  background: linear-gradient(180deg, rgba(33, 117, 139, 1) 0%, rgba(236, 122, 144, 1) 100%) !important;

  animation: slideDown 4s ease forwards;
}

@media (min-width: 1024px) {
  .line_color {
    height: 550px;
    top: 55px;
  }
}

@keyframes slideDown {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

.title {
  text-align: center;
  letter-spacing: 0.3px;
  font-size: 60px;
  font-weight: 600;
  margin-top: 150px;
}

@media (min-width: 1024px) {
  .title {
    font-size: 200px;
    margin-top: 50px;
  }
}

@media (min-width: 1400px) {
  .title {
    font-size: 250px;
    margin-top: 50px;
  }
}

.paragraph_services p {
  font-size: 12px;
  margin-top: 47px;
  margin-bottom: 35px;
}

@media (min-width: 1024px) {
  .paragraph_services {
    display: flex;
    justify-content: end;
    width: 100%;
    position: relative;
    top: -100px;
  }

  .paragraph_services_text {
    width: 50%;
    margin-right: -20px;
  }

  .paragraph_services p {
    font-size: 20px;
  }
}

.products {
  background-color: #000000;
  position: relative;
}

.title_products {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 61px;
}

.title_products h2 {
  font-size: 20px;
  color: #ef7a90;
}

.title_products :nth-child(2) {
  width: 18px;
  padding-bottom: 20px;
}

@media (min-width: 1024px) {
  .title_products h2 {
    font-size: 50px;
    font-weight: 600;
  }
}

.paragraph_products {
  color: #ffffff;
  font-size: 12px;
  margin-top: 27px;
}

@media (min-width: 1024px) {
  .paragraph_products {
    font-size: 18px;
    width: 70%;
  }
}

.list_products_contain {
  margin-top: 27px;
}

@media (min-width: 1024px) {
  .list_products_contain {
    width: 68%;
  }
}

.list_products {
  margin: 14px 0px;
}

.list_products :nth-child(1) {
  color: #ef7a90;
  font-size: 11px;
  display: inline-block;
}

.list_products :nth-child(2) {
  color: #ffffff;
  font-size: 12px;
  display: inline-block;
  margin-left: 20px;
}

@media (min-width: 1024px) {
  .list_products {
    margin: 30px 0px;
  }

  .list_products :nth-child(1) {
    font-size: 18px;
  }

  .list_products :nth-child(2) {
    font-size: 18px;
    margin-left: 30px;
  }
}

.button_products {
  margin-top: 10px;
  text-align: end;
}

.button_products button {
  cursor: pointer;
}

@media (min-width: 1024px) {
  .button_products {
    text-align: start;
    margin-top: 60px;
  }
}

.img_products {
  margin-top: 80px;
  display: flex;
  justify-content: center;
}

.img_products img {
  width: 80%;
}

@media (min-width: 1024px) {
  .img_products {
    justify-content: end;
  }

  .img_products img {
    max-width: 500px;
  }
}

.absolute_text_products {
  color: #ffffff;
  font-size: 11px;
  position: absolute;
  right: -10px;
  writing-mode: vertical-lr;
}

.container_arrow_products {
  width: 30%;
  display: flex;
  justify-content: end;
}

.arrow_destok_products {
  width: 200px;
  rotate: 90deg;
  position: relative;
  top: -200px;
  right: -60px;
}

@media (min-width: 1024px) {
  .flex_list_products {
    display: flex;

    align-items: end;
    gap: 2%;
  }
}
