#error{
    width: 100%;
    margin: auto;
    height: 250px;
    padding-top: 80px;
    max-width: 600px;
}

#error .error_mensaje{
    font-size: 40px;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#error .error_mensaje div:first-child{
    line-height: 1;
}


#error .error_mensaje div:last-child{
    font-size: 100px;
    font-weight: 600;
    line-height: 1;
}

#error .error_mensaje div span{
    display: block;
}

#error .error_mensaje div span:first-child{
    color: #EC7A90;
    font-size: 16px;
    text-align: center;
}

#error .error_mensaje div span:last-child{
    font-size: 40px;
}

#error .error_imagen{
    position: absolute;
    right: 0;
    left: 0;
    margin: 0 auto;
    bottom: 15%;
    max-width: 600px;
}

#error .error_imagen img{
    width: 100%;
}

#error .error_menu{
    position: absolute;
    bottom: 8%;
    left: 0;
    right: 0;
    margin: 0px auto;
    display: flex;
    justify-content: space-between;
    width: 90%;
}

#error .error_menu div:first-child{
    font-size: 10px;
}

#error .error_menu div:last-child{
    font-size: 12px;
}

.link{
    text-decoration: none;
    color: white;
}

.pink{
    color: #EC7A90;
}


@media only screen and (min-width: 1024px){

    #error .error_mensaje {
        flex-direction: row;
        justify-content: center;
    }

    #error .error_mensaje div:first-child {
        line-height: 1;
        padding-right: 35px;
    }

    #error .error_mensaje div span:first-child {
        font-size: 16px;
        text-align: right;
    }

    #error .error_imagen {

        bottom: 10%;

    }
}