.container_block_two,
.container_block_two_black {
  position: relative;
}

.container_block_two {
  background-color: #f3f3f3;
}

.container_block_two_black {
  background-color: black;
}

.container_block_two span,
.container_block_two_black span {
  color: #ef7a90;
}

.dodle_block_two {
  position: absolute;
  right: 20px;
  top: 45px;
}

@media (min-width: 1024px) {
  .dodle_block_two {
    right: 10%;
  }
}

.text_block_two {
  color: black;
  font-size: 16px;
  padding: 55px 0px;
  font-weight: 600;
  width: 95%;
}

.text_block_two_black {
  color: #f3f3f3;
  font-size: 12px;
  padding-top: 70px;
  font-weight: 500;
  width: 88%;
}

@media (min-width: 1024px) {
  .text_block_two {
    font-size: 20px;
  }

  .text_block_two_black {
    font-size: 20px;
  }
}

.line_block_two,
.line_block_two_black {
  position: absolute;
  height: 100%;
  width: 8px;
  background: transparent linear-gradient(180deg, #ef7a90 0%, #24748a 100%) 0% 0% no-repeat padding-box;
}

.line_block_two {
  left: 0;
  top: 0;
}

.line_block_two_black {
  right: 0;
  top: 0;
}

.button_block_two {
  margin-top: 25px;
  padding-bottom: 38px;
  text-align: end;
}

.button_block_two button {
  cursor: pointer;
}

@media (min-width: 1024px) {
  .limitate_size_desktop {
    width: 80%;
    margin: 0 auto;
    padding: 50px 0px;
  }
}
