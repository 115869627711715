#contacto {
  padding-top: 125px;
  background: #000000;
}

#contacto .contacto_header {
  color: white;
  width: 70%;
  margin: 0px auto;
  font-size: 24px;
  font-weight: 600;
  opacity: 0;
  animation: fadeIn 1s forwards 0.4s;
  display: flex;
  align-items: flex-end;
  padding-bottom: 30px;
}

.contacto_header div {
  padding-right: 20px;
}

.contacto_header img {
  filter: invert(1);
  width: 70%;
}

.arrow_black_contact {
  fill: black;
  width: 50px !important;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

#contacto .contacto_header span {
  display: block;
  padding-top: 30px;
  color: #ef7a90;
}

#contacto .formulario {
  margin: auto;
  padding: 0 50px;
  transition: 1s all;
}

.error-message {
  color: #EF7A90;
  font-size: 12px;
  padding: 5px 0px;
  font-family: 'Poppins', sans-serif;
}

#contacto .formulario .campos {
  width: 100%;
  padding: 10px 0;
}

#contacto .formulario .campos label,
input,
textarea {
  width: 80%;
  display: block;
  margin: auto;
  padding: 20px;
  background: #000000 !important;
  border: 1px solid white !important;
  color: #ffffff !important;
}

input::placeholder,
textarea::placeholder {
  color: #888888;
  opacity: 1;
}

input[type='submit'] {
  margin: 0 auto;

  border-radius: 30px;
  border: 2px white solid !important;
  background: #000;
  background: linear-gradient(180deg, #ef7a90 0%, #24748a 100%), #000 !important;

  cursor: pointer;
  font-weight: 700;
}

.fadeout {
  opacity: 0;
}

.clearfix {
  overflow: auto;
  clear: both;
}

@media only screen and (min-width: 768px) {
  #contacto {
    padding-right: 90px;
  }

  #contacto .contacto_header {
    font-size: 40px;
    width: 65%;
    float: right;
  }

  #contacto .formulario {
    width: 65%;
    float: right;
    padding: 0;
  }

  #contacto .formulario .campos label,
  input,
  textarea {
    width: 90%;
    margin: inherit;
  }
}

.title_contact {
  line-height: 40px;
}

.title_contact span {
  line-height: 0px;
  text-align: end;
}

@media (min-width: 1024px) {
  .title_contact span {
    line-height: 35px;
  }
}
