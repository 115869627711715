.title_clients {
  font-size: 20px;
}

@media (min-width: 1024px) {
  .title_clients {
    font-size: 40px;
  }
}

.content_title {
  padding-top: 60px;
  display: flex;
  align-items: center;
  gap: 12px;
}

.flex_title_clients {
  display: flex;
  margin-top: 85px;
  gap: 20px;
  align-items: center;
}

.subtitle_clients {
  font-size: 20px;
  color: #ef7a90;
}

@media (min-width: 1024px) {
  .subtitle_clients {
    font-size: 40px;
  }
}

.paragraph_for {
  color: white;
  margin-top: 44px;
  font-size: 11px;
}

.container_images_clients {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
  margin: 70px 0px;
}

@media (min-width: 1024px) {
  .container_images_clients {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 80px 30px;
    padding-bottom: 60px;
  }
}

.image_clients {
  display: flex;
  justify-content: center;

  align-items: center;
}

.image_clients img {
  width: 100%;
  height: 60px;
  object-fit: contain;
}

@media (min-width: 1024px) {
  .image_clients img {
    width: 100%;
    object-fit: contain;
    height: 120px;
  }
}
