/* CASOS */
.contenedor_caso {
  opacity: 0;
  animation: fadeIn 1s forwards;
  background: #ffffff;
}

.caso_modal {
  top: 0;
  position: absolute;
  background: #ffffff;
  height: 100vh;
  width: 100%;
  left: 0;
}

.header_caso {
  height: 0vh;
  display: flex;
  align-items: center;
  background-size: cover !important;
}


@keyframes header_caso {
  0% {
    height: 0vh;
  }
  100% {
    height: 50vh;
  }
}

.header_content {
  position: absolute;
  top: 17%;
  width: 35%;
  opacity: 0;
  padding-left: 60px;
  color: #fff;
}

.header_content h2 {
  font-weight: 600;

  line-height: 1.2;
}

.header_content p {
  padding-top: 25%;
  font-size: 12px;
  line-height: 1.3;
}

.content_body {
  padding-left: 60px;
  padding-top: 50px;
  position: relative;
}

.multimedia_contenedor {
  opacity: 0;

}

.separador_content_body {
  display: flex;
  padding-top: 50px;
  width: 90%;
}

.separador_content_body .center {
  width: 85%;
}

.separador_content_body .center b {
  font-size: 16px;
  margin-bottom: 15px;
  display: block;
  font-weight: 600;
}

.center p {
  font-size: 14px;
}

.separador_content_body .center b span {
  color: #ef7a90;
}

.separador_content_body .center div {
  width: 95%;
}

.separador_content_body .center div span {
  font-weight: 600;
}

.separador_content_body .center div ul {
  list-style: none;
}

.separador_content_body .center div ul .left {
  margin-left: 20px;
}

.content_body .lateral {
  position: absolute;
  right: 2%;
  top: 25%;
  transform: rotateZ(90deg);
  font-size: 12px;
}

.content_body .number {
  position: absolute;
  right: 5%;
  text-align: center;
  font-size: 50px;
  font-weight: 600;
  transform: rotateY(180deg);
  transition: 1s all;
  opacity: 0.6;
  top: 35%;
}

.rotate_number {
  transform: rotateY(0deg) !important;
  opacity: 1 !important;
}

.body_caso {
  opacity: 0;
}

/* Multimedia casos */
.separador_multimedia {
  display: flex;
  padding-top: 100px;
  justify-content: flex-end;
}

.separador_multimedia .imagenes {
  width: 90%;
  max-width: 1200px;
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .separador_multimedia .imagenes img {
    width: 100% !important;
  }
}



.separador_multimedia .imagenes video {
  width: 85%;
}

.separador_multimedia .icons_multimedia {
  width: 10%;
  font-size: 10px;
  text-align: center;
  position: relative;
}

.icons_multimedia img {
  width: 40%;
  padding: 5px 0;
  margin: 0px auto;
}

.icons .icon {
  max-width: 70px;
  margin: 0px auto;
}

.galeria {
  padding-top: 25px;
  position: absolute;
  width: 100%;
  right: 0%;
  top: 50%;
}

.icons_multimedia .lateral {
  opacity: 1;
  display: block;
  font-size: 9px;
  transform: rotateZ(270deg);
  margin: 25px 0px;
}

.multimedia_contenedor .all_gallery {
  width: 90%;
  text-align: right;
  max-width: 1200px;
}

.multimedia_contenedor .all_gallery img {
  width: 100%;
}

.multimedia_contenedor .all_gallery_flex {
  width: 100%;
}

.multimedia_contenedor .all_gallery_flex img {
  width: 49.5%;
}

.next_caso {
  margin: 0 auto;
  width: 100%;
  cursor: pointer;
  margin-top: 100px;
  max-width: 55px;
  opacity: 0;
  visibility: hidden;
  animation: next_caso 1s forwards 3s;
}

@keyframes next_caso {
  to {
    visibility: visible;
    opacity: 1;
  }
}

.next_caso p {
  font-size: 10px;
  margin-left: 30px;
  line-height: 1.2;
}

.next_caso img {
  margin: 20px 0;
  width: 100%;
}

.flecha_caso_animacion {
  animation: flecha_caso_animacion 6s infinite;
}

@keyframes flecha_caso_animacion {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0.6;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

/* Responsive */
@media only screen and (min-width: 768px) {
  .content {
    width: 85%;
  }

  .header_content {
    position: static;
    display: flex;
    color: black;
    padding: 60px 0;
    /* justify-content: space-between; */
    align-items: center;
    width: 75%;
    margin: 0px auto;
  }

  .header_content h2 {
    font-size: 50px;
    padding-right: 10%;
    width: 40%;
  }

  .header_content p {
    padding-top: 0px;
    font-size: 25px;
    width: 30%;
  }

  @keyframes header_caso {
    0% {
      height: 0vh;
    }
    100% {
      height: 40vh;
    }
  }

  .icons_multimedia .lateral {
    font-size: 15px;
    margin: 65px 15px;
  }

  .icons_multimedia img {
    width: 40%;
    padding: 20px 0;
    margin: 0px auto;
  }

  .content_body {
    padding-left: 0px;
  }

  .content_body .lateral {
    top: -20%;
    right: 4%;
  }

  .content_body .number {
    top: -8%;
    font-size: 65px;
  }

  .separador_content_body {
    width: 75%;
    margin: 0px auto;
  }

  .separador_content_body .center {
    display: flex;
    /* justify-content: space-around; */
    width: 100%;
    margin: 0px auto;
  }

  .separador_content_body .center b {
    font-size: 25px;
    width: 30%;
    padding-right: 20%;
  }

  .separador_content_body .center p,
  .separador_content_body .center div {
    width: 45%;
  }

  .separador_multimedia .imagenes {
    margin-bottom: 25px;
    margin: 0px auto;
  }

  .separador_multimedia .imagenes img {
    width: 49.5%;
  }
  .separador_multimedia .imagenes img:nth-child(odd) {
    margin-right: 5px;
  }
  .separador_multimedia .icons_multimedia {
    width: 10%;
  }

  .multimedia_contenedor .all_gallery {
    /* width: 85%; */
    margin-bottom: 25px;
  }

  .multimedia_contenedor .all_gallery img {
    width: 85%;
  }

  .multimedia_contenedor .all_gallery_flex {
    margin: 0px auto;
    margin-bottom: 25px;
  }

  .multimedia_contenedor .all_gallery_flex img {
    width: 49.5%;
  }

  /* Backgrounds de cada caso */
  #union_caso .header_caso {
    background-position: 0% 75%;
  }

  #rappi_caso .header_caso {
    background-position: 50% 75% !important;
  }

  #sura_caso .header_caso {
    background-position: 100% 40%;
  }

  #caravana_caso .header_caso {
    background-position: 50% 45%;
  }

  #estereopicnic_caso .header_caso {
    background-position: 50% 75%;
  }

  #museo_memorias_caso .header_caso {
    background-position: 0% 75%;
  }

  #cali_solidario_caso .header_caso {
    background-position: 0% 75%;
  }

  #ricardo_pava_caso .header_caso {
    background-position: 90% 0%;
  }

  #sello_disquero_caso .header_caso {
    background-position: 0% 75%;
  }


}


@media only screen and (max-width: 768px){
  .header_caso {
    background-position: 0% 0% !important;
  }
}

.header_caso_animation {
  animation: header_caso 1s forwards 1.5s;
}


.fadeIn_1 {
  animation: fadeIn 1s forwards 2s;
}
.fadeIn_2 {
  animation: fadeIn 1s forwards 2.5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}