#servicio {
}

#servicio h2 {
    margin-top: 100px;
    margin-bottom: 20px;
}

#servicio .content_manifesto {
  padding-top: 100px;
}

.item_caracteristica {
  margin-bottom: 50px;
}

#servicio .about_fixed {
  display: block;
  transform: rotateZ(90deg);
  position: fixed;
  right: 0;
  top: 85%;
  font-size: 10px;
  transition: 0.5s all;
  z-index: 1000;
}

#servicio .manifesto .titulo {
  color: #ef7a90;
  border-bottom: 2px solid #ef7a90;
  display: block;
  padding-left: 0;
  font-weight: 600;
  margin-bottom: 10px;
  padding-bottom: 10px;
}
#servicio .button_container{
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
}
#servicio .button{
    background: transparent linear-gradient(180deg, #EF7A906B 0%, #24748A7A 100%) 0% 0% no-repeat padding-box;
    color: white;
    padding: 10px 25px;
    cursor: pointer;
    width: fit-content;
    border: 2px solid white;
    border-radius: 100px;
    font-weight: 600;
}

@media only screen and (min-width: 768px) {
  #servicio h2 {
    font-size: 50px;
  }

  #servicio .about_fixed {
    right: 1%;
    font-size: 13px;
  }

  #servicio .manifesto .descripcion_container {
    display: flex;
    width: 100%;
    flex-direction: row-reverse;
  }

  #servicio p {
    width: 60%;
  }
}
