.desc h2 {
  text-align: right;
  font-size: 25px;
  padding-top: 100px;
  padding-bottom: 120px;
  padding-right: 50px;
  font-weight: 600;
  color: #221e1d;
}

.shadow_1 {
  position: absolute;
  background: rgb(33, 117, 139);
  background: linear-gradient(180deg, rgba(33, 117, 139, 1) 0%, rgba(236, 122, 144, 1) 100%);

  width: 100%;
  height: 85%;
  top: 15px;
  left: 20px;

  border: 2px black solid;
  z-index: -1;
}

.shadow_2 {
  position: absolute;
  background: rgb(33, 117, 139);
  background: linear-gradient(0deg, rgba(33, 117, 139, 1) 0%, rgba(236, 122, 144, 1) 100%);
  width: 100%;
  height: 90%;
  top: 10px;
  left: 10px;
  z-index: -1;
  border: 2px black solid;
}

.container_staff {
  border: 2px black solid;
  background-color: #ffffff;
  width: 90%;
  position: relative;
}

.container_staff img {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.swiper_staff {
  width: 100%;
  padding: 10px;
}

.name_staff {
  font-weight: 600;
}
.rol_staff {
  color: #ef7a90;
  font-weight: 600;
  font-size: 12px;
}

.album_staff p:first-child {
  font-weight: 600;

}

.album_staff p:nth-child(2) {
  font-size: 12px;
  line-height: 10px;
}

.paddin_staff {
  padding: 10px;
}

.arrows_slider_staff {
    margin-top: 50px;
  display: flex;
  justify-content: center;
  gap: 20px;
}

.arrows_slider_staff img:nth-child(1) {
  width: 50px;
  cursor: pointer;
}

.spotify{
  margin-top: 27px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.icon_spotify{
  width: 40px;
  cursor: pointer;
}