.title_range {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-top: 100px;
}

.title_range p {
  font-size: 20px;
  font-weight: 600;
}

.slash_range {
  width: 30px;
}

.paragraph_range {
  margin-top: 20px;
  font-size: 12px;
}

.img_range {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 70px 0px;
}

.img_range img {
  object-fit: contain;
  width: 50%;
}

@media (min-width: 1024px) {
  .title_range p {
    font-size: 40px;
  }

  .paragraph_range {
    font-size: 14px;
  }

  .flex_range_desktop {
    display: flex;
    justify-content: space-between;
    margin-bottom: 100px;
  }
  .group {
    width: 70%;
  }

  .img_range {
    width: 40%;
  }

  .img_range img {
    width: 100%;
    max-width: 300px;
    transform: translateY(100px);
  }
}
