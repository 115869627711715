/* Animacion */

.casos_fixed {
  display: block;
  transform: rotateZ(90deg);
  position: fixed;
  right: 0;
  top: 85%;
  font-size: 10px;
  transition: 0.5s all;
  z-index: 1000;
  opacity: 0;
}

.fadein {
  opacity: 1;
}

.fadeout {
  opacity: 0 !important;
}
.home_animacion .animacion_contenedor {
  height: 90vh;
  position: relative;
}

.home_animacion .animacion, #servicio
 .animacion {
  position: relative;
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.animacion .texto {
  font-size: 40px;
  text-align: left;
  font-weight: 600;
  padding-right: 10%;
  display: block;
}

.animacion .movil {
  display: block;
}

.animacion .web {
  display: none;
}

.animacion .texto p {
  line-height: 1;
}

.animacion .texto p:nth-child(1) {
  opacity: 0;
  margin-top: 150px;
  animation: texto_1 2s forwards 2s;
}

.animacion .texto p:nth-child(2) {
  background: linear-gradient(
    90deg,
    rgba(33, 117, 139, 1) 0%,
    rgba(236, 122, 144, 1) 100%
  );
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: 20px;
  opacity: 0;
  animation: texto_2 2s forwards 2.2s;
}

.animacion .texto p:nth-child(3) {
  background: linear-gradient(
    90deg,
    rgba(33, 117, 139, 1) 0%,
    rgba(236, 122, 144, 1) 100%
  );
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: 20px;
  opacity: 0;
  animation: texto_2 2s forwards 2.4s;
}

.animacion .texto p:nth-child(4) {
  margin-top: 20px;
  opacity: 0;
  animation: texto_2 2s forwards 2.6s;
}

.animacion .texto p:nth-child(5) {
  margin-top: 20px;
  opacity: 0;
  animation: texto_2 2s forwards 2.8s;
}

@keyframes texto_1 {
  to {
    opacity: 1;
    margin-top: 50px;
  }
}

@keyframes texto_2 {
  to {
    opacity: 1;
    margin-top: 0px;
  }
}

.animacion svg {
  /* max-width: 500px; */
  padding-top: 100px;
  width: 52%;
}

/* PARA ELIMINAAAAARRR ELIMINAR */
/* Animacion svg del home  */
.home_lineas_1,
.home_lineas_2 {
  /* fill: none; */
  stroke-miterlimit: 10;
  stroke-dasharray: 15000.41943359375;
  stroke-dashoffset: 15000.41943359375;
  stroke-width: 3px;
}

.home_lineas_1 {
  /* transform: translate(-4%,0); */
  animation: cls_animation_linea_1 30s linear forwards 1.5s;
}

.home_lineas_2 {
  opacity: 0;
  animation: cls_animation_linea_2 1s linear forwards 1s;
}

@keyframes cls_animation_linea_1 {
  15% {
    /* transform: translate(0,0); */
  }
  100% {
    stroke-dashoffset: 0;
    /* transform: translate(0,0); */
  }
}

@keyframes cls_animation_linea_2 {
  to {
    opacity: 1;
  }
}

.home_animacion .flecha_abajo {
  width: 100%;
  max-width: 350px;
  margin: 0px auto;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 15%;
}

.home_animacion .flecha_abajo img {
  opacity: 0;
  width: 10%;
  animation: fadeIn 4s forwards 4s;
}

.home_animacion .flecha_abajo div {
  width: 15%;
  font-size: 9px;
  position: absolute;
  right: 20%;
  opacity: 0;
  line-height: 1.3;
  animation: flecha_abajo_div 2s forwards 4.5s;
}

@keyframes flecha_abajo_div {
  to {
    opacity: 1;
    right: 25%;
  }
}

/* Para lista de casos */
#home .detalles {
  font-size: 15px;
  opacity: 0;
  animation: detalles 1s forwards 0.9s;
}

@keyframes detalles {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

#home {
  padding: 60px 50px;
}

#home .contenedor_casos {
  position: relative;
  top: 40px;
}

#home .contenedor_casos li {
  list-style: none;
  cursor: pointer;
  /* max-height: 350px; */
  overflow: hidden;
  color: white;
}

#home .caso {
  position: relative;
  margin: auto;
}

#home .caso .titulo {
  position: absolute;
  z-index: 100;
  height: 80%;
  width: 90%;
  margin: auto;
  left: 0;
  right: 0;
  display: flex;
  justify-content: initial;
  padding-top: 10%;
}

#home .caso .titulo p {
  font-size: 16px;
  font-weight: 600;
  width: 55%;
}

#home .caso .titulo p span {
  display: block;
  font-size: 10px;
  font-weight: 400;
}

#home .caso .titulo .descripcion {
  font-size: 12px;
  width: 25%;
  opacity: 0;
  display: none;
  transition: 1s all;
}

#home .caso:hover div:nth-child(2) {
  opacity: 1 !important;
}

#home .caso:hover div:nth-child(2) img {
  filter: brightness(0.6);
}

#home .caso .imagen img {
  width: 100%;
  display: block;
  /* filter: brightness(0.7); */
  transition: 1s all;
}

#home .caso .imagen img:hover {
  filter: brightness(1);
}

.fondo_blanco {
  position: fixed;
  top: 50%;
  left: 0;
  background: #ffffff;
  height: 0vh;
  width: 100%;
  z-index: 1000;
}

.fondo_blanco_animation {
  animation: fondo_blanco 4s forwards;
}

@keyframes fondo_blanco {
  0% {
    height: 0;
  }
  50% {
    height: 50vh;
  }
  100% {
    height: 50vh;
  }
}

.fondo_blanco_top {
  position: fixed;
  top: 50%;
  left: 0;
  background: #ffffff;
  height: 0vh;
  width: 100%;
  z-index: 1000;
}

.fondo_negro {
  background: black !important;
}

.fondo_blanco_animation_top {
  animation: fondo_blanco_top 4s forwards;
}

@keyframes fondo_blanco_top {
  0% {
    top: 51%;
    height: 0;
  }
  50% {
    top: 0;
    height: 50vh;
  }
  100% {
    top: 0;
    height: 50vh;
  }
}

.lineas {
  stroke-dasharray: 10000.41943359375;
  stroke-dashoffset: 10000.41943359375;
  animation: dash 6s linear forwards;
}

.tubo_2 {
  fill: white;
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

.go_to {
  position: fixed;
  top: 50%;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0px auto;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  color: black;
}

ellipse {
  opacity: 0;
  animation: fadeIn 1s forwards 2.5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* RESPONSIVE */
@media only screen and (min-width: 768px) {
  #home {
    width: 80%;
    margin: 0px auto;
  }

  #home .detalles {
    width: 50%;
    padding: 50px 0;
  }

  .casos_fixed {
    right: 2%;
    font-size: 15px;
  }

  .animacion .movil {
    display: none;
  }

  .animacion .web {
    display: block;
  }

  .animacion .texto {
    padding-right: 10%;
  }

  .animacion .texto p:nth-child(2) {
    -webkit-text-fill-color: black;
  }

  .animacion .texto p span:nth-child(1) {
    background: linear-gradient(
      90deg,
      rgba(33, 117, 139, 1) 0%,
      rgba(236, 122, 144, 1) 100%
    );
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .animacion .texto p span:nth-child(2) {
    background: linear-gradient(
      90deg,
      rgba(33, 117, 139, 1) 0%,
      rgba(236, 122, 144, 1) 100%
    );
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .animacion .texto p:nth-child(3) {
    -webkit-text-fill-color: black;
  }

  .home_animacion .flecha_abajo {
    /* padding: 200px 0 50px 0; */
  }

  #home .contenedor_casos {
    margin: auto;
    display: flex;
    flex-wrap: wrap;
  }

  #home .contenedor_casos li {
    /* max-height: 190px; */
    width: 50%;
    transition: 2s all;
    margin: initial;
  }


  #home .animacion_li li:nth-child(even) {
    transform: translate(0, 15%);
  }

  #home .caso {
    width: 50%;
  }
}

@media only screen and (min-width: 1024px) {
  .animacion .texto {
    font-size: 60px;
  }

  #home .contenedor_casos li {
    /* max-height: 250px; */
  }

  #home .caso .titulo {
    justify-content: space-around;
  }

  #home .caso .titulo p {
    font-size: 24px;
  }

  #home .caso .titulo .descripcion {
    display: block;
    font-size: 12px;
    margin-top: 25px;
  }

  #home .caso .titulo .descripcion span:nth-child(3) {
    font-size: 12px;
    margin-top: 15px;
    display: block;
  }
}

@media only screen and (min-width: 1200px) {
  .animacion .texto {
    font-size: 80px;
  }

  /* #home .contenedor_casos li {
        max-height: 350px;
    } */
}

@media only screen and (min-width: 1100px) and (max-height: 650px) {
  .animacion svg {
    padding-top: 50px;
    width: 40%;
  }

  .animacion .texto {
    font-size: 65px;
  }

  .home_animacion .flecha_abajo {
    bottom: 10%;
  }
}
