.title_lines {
  margin-top: 86px;
  display: flex;
  justify-content: space-between;
}

.size_arror_lines {
  width: 120px;
}

.title_lines p {
  font-size: 20px;
  font-weight: 600;
}

@media (min-width: 1024px) {
  .title_lines p {
    font-size: 30px;
  }
}

.line_information {
  margin: 55px 0px;
}

.line_information :nth-child(1) {
  color: #24748a;
  font-size: 12px;
}

.line_information :nth-child(2) {
  color: #ef7a90;
  font-size: 20px;
  max-width: 250px;
  font-weight: 600;
  line-height: 25px;
}

@media (min-width: 1024px) {
  .line_information {
  }
  .line_information :nth-child(1) {
    font-size: 18px;
  }
  .line_information :nth-child(2) {
    font-size: 20px;
    max-width: unset;
  }
}

.line_decoration {
  width: 100%;
  height: 3px;
  margin: 10px 0px;
  background: transparent linear-gradient(90deg, #ef7a90 0%, #24748a 100%) 0% 0% no-repeat padding-box;
}

@media (min-width: 1024px) {
  .line_decoration {
    width: 60%;
  }
}

.text_line {
  display: flex;
  justify-content: end;
  align-items: end;
}

.text_line p {
  width: 80%;
  color: black !important;
  font-weight: 550;
}

@media (min-width: 1024px) {
  .text_line p {
    width: 60%;
    font-size: 14px !important;
    font-weight: 500;
  }
}
