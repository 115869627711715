@import 'react-alice-carousel/lib/alice-carousel.css';

#acerca .about {
  margin: auto;
  padding-top: 50%;
  opacity: 0;
  animation: about 1s forwards 0.6s;

  font-size: 24px;
  font-weight: 600;
}

#acerca .about_fixed {
  display: block;
  transform: rotateZ(90deg);
  position: fixed;
  right: 0;
  top: 85%;
  font-size: 10px;
  transition: 0.5s all;
  z-index: 1000;
}

.acerca_header {
  height: 95vh;
  position: relative;
}

.change_white_color {
  color: white;
}

#acerca .about span {
  display: block;
  width: 80%;
  margin: 0 auto;
  max-width: 500px;
  line-height: 1;
}

#acerca .about span p {
  color: #ef7a90;
  font-size: 40px;
}

#acerca .about hr {
  margin: 20px 0;
  background: black;
  width: 0%;
  height: 1px;
  border: none;
  opacity: 0;
  animation: aboutHr 1.8s forwards 0.5s;
}

@keyframes aboutHr {
  to {
    width: 100%;
    opacity: 1;
  }
}

@keyframes about {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

#acerca .flecha_abajo {
  width: 100%;
  text-align: center;
  opacity: 0;
  animation: fadeIn 1s forwards 3s;
  position: absolute;
  bottom: 15%;
}

#acerca .flecha_abajo img {
  width: 10%;
  animation: flecha_abajo_img 4s forwards 3s;
  max-width: 30px;
}

#acerca .galeria h2 {
  text-align: right;
  font-size: 25px;
  padding: 0 0 100px 0;
  color: #221e1d;
  font-weight: 600;
}

.bg_black{
  background-color: black;
}
.manifesto {
  color: #fff;
  position: relative;
  margin-top: 100px;
  padding-top: 50px;
  max-width: 1200px;
  margin: 0 auto;
}

.linea_color_k {
  position: absolute;
  width: 2px;
  left: 0;
  right: 0.5%;
  top: 13%;
  margin: 0 auto;
  height: 200px;
  transition: 4s all;
  background: linear-gradient(180deg, rgba(33, 117, 139, 1) 0%, rgba(236, 122, 144, 1) 100%) !important;
}
@media (min-width: 724px) {
  .linea_color_k {
    width: 4px !important;
  }
}

.linea_color_top_5 {
  top: 7%;
}

.linea_color_top_9 {
  top: 11%;
}

.manifesto .indio {
  position: absolute;
  top: 100px;
  left: 10%;
  padding: 1px 5px;
  width: 20%;
  max-width: 80px;
  text-align: center;
  font-size: 12px;
}

.manifesto .border-gradient {
  border: 10px solid;
  border-image-slice: 1;
  border-width: 1px;
}

.manifesto li {
  padding-bottom: 25px;
  list-style: none;
}

.manifesto .title_manifesto {
  display: flex;
  margin: 15px 0;
  font-style: italic;
}

.title_manifesto div:nth-child(1) {
  font-size: 12px;
  color: #ef7a90;
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title_manifesto div:nth-child(2) {
  font-size: 13px;
  width: 80%;
  font-weight: 600;
}

.desc_manifesto {
  font-size: 13px;
  padding-left: 20%;
  padding-right: 10%;
  font-weight: 300;
}

.content_manifesto {
  padding-top: 240px;
  padding-bottom: 100px;
}

.content_manifesto h2 {
  text-align: center;
  font-size: 3.5rem;
  width: 90%;
  margin: 0 auto;
  padding-bottom: 90px;
  font-weight: 600;
}

.content_manifesto p {
  padding-left: 60px;
  padding-right: 30px;
}

@media only screen and (min-width: 768px) {
  #acerca .about {
    padding-top: 200px;
  }

  #acerca .about_fixed {
    right: 2%;
    font-size: 13px;
  }

  #acerca .about span {
    display: block;
    padding-left: 0px;
    padding-right: 0px;
    width: 60%;
    font-size: 40px;
    margin: 0px auto;
  }

  .content_manifesto h2 {
    font-size: 8rem;
  }

  .title_manifesto div:nth-child(1) {
    font-size: 17px;
  }

  .title_manifesto div:nth-child(2) {
    font-size: 20px;
  }

  .desc_manifesto {
    font-size: 20px;
  }

  .content_manifesto ul {
    width: 60%;
    float: right;
  }

  .linea_color_k {
    width: 0.2%;
    height: 350px;
  }
}

.mt_pink {
  margin-top: 10px;
  font-size: 25px !important;
}

@media (min-width: 724px) {
  .mt_pink {
    font-size: 40px !important;
  }
}

/* /////////////////////// */

.text_manifesto {
  top: 90px;
  width: 90%;
  right: 50%;
  transform: translateX(50%);
  position: absolute;
}

.text_manifesto p {
  font-size: 9px !important;
  font-weight: 400 !important;
}

@media (min-width: 1024px) {
  .text_manifesto {
    width: 45%;
    top: 230px;
    right: 0px;
    transform: translate(0);
  }

  .text_manifesto p {
    font-size: 14px !important;
  }
}
