#contact_white {
  padding-top: 125px;
  background: white;
}

#contact_white .contacto_header {
  color: black;
  width: 70%;
  margin: 0px auto;
  font-size: 24px;
  font-weight: 600;
  opacity: 0;
  animation: fadeIn 1s forwards 0.4s;
  display: flex;
  align-items: flex-end;
  padding-bottom: 30px;
}

.contacto_header div {
  padding-right: 20px;
}

.contacto_header img {
  filter: invert(1);
  width: 70%;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

#contact_white .contacto_header span {
  display: block;
  padding-top: 30px;
  color: #ef7a90;
}

#contact_white .formulario {
  margin: auto;
  padding: 0 50px;
  transition: 1s all;
}

#contact_white .formulario .campos {
  width: 100%;
  padding: 10px 0;
}

#contact_white .formulario .campos label,
input,
textarea {
  width: 80%;
  display: block;
  margin: auto;
  padding: 20px;
  background: white !important;
  border: 1px solid black !important;
  /* font-family: 'Poppins'; */
  color: black !important;
}

input[type="submit"] {
  width: 100%;
  max-width: 150px;
  padding: 10px;
  border-radius: 30px;
  background: rgb(33, 117, 139);
  background: linear-gradient(180deg, rgba(33, 117, 139, 1) 100%, rgba(236, 122, 144, 1) 0%);
  cursor: pointer;
}

.fadeout {
  opacity: 0;
}

.clearfix {
  overflow: auto;
  clear: both;
}

@media only screen and (min-width: 768px) {
  #contact_white {
    padding-right: 90px;
  }

  #contact_white .contacto_header {
    font-size: 40px;
    width: 65%;
    float: right;
  }

  #contact_white .formulario {
    width: 65%;
    float: right;
    padding: 0;
  }

  #contact_white .formulario .campos label,
  input,
  textarea {
    width: 90%;
    margin: inherit;
  }
}
