#gracias{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* height: 100vh; */
}

#gracias .gracias_contenedor{
    position: relative;
    display: flex;
    width: 100%;
    justify-content: center;
    max-width: 350px;
    margin: 0px auto;
    z-index: 5000;
    margin-top: 200px;
}

#gracias .gracias_contenedor .gracias_iconos{
    width: 7%;
    padding: 0px 15px 0 0px;
}


#gracias .gracias_contenedor .gracias_iconos img{
    display: block;
    width: 100%;
}

#gracias .gracias_contenedor .gracias_texto{
    color: white;
    border: 1px solid #FFFFFF;
    padding: 30px 25px;
    font-size: 24px;
}

#gracias .gracias_contenedor .gracias_texto span{
    color: #EF7A90;
}

#gracias .gracias_contenedor .gracias_flecha {
    position: absolute;
    bottom: -23.1%;
    right: 9.6%;
    border: 1px solid white;
    line-height: 0;
    width: 35%;
}

#gracias .gracias_contenedor .gracias_flecha img{
    width: 100%;
}



.gracias_inicio{
    position: relative;
    margin-top: 15%;
    /* z-index: 10000; */
}

.gracias_inicio .item_menu{
    text-decoration: none;
    color: white;
}

@media only screen and (min-width: 768px){


    .gracias_inicio{
        margin-bottom: 0;
        margin-top: 8%;
    }

    #gracias .gracias_contenedor {
        max-width: 450px;
        margin-top: 100px;
    }
    
    

    #gracias .gracias_contenedor .gracias_flecha {
        bottom: -20.5%;
        right: 0%;
    
    }

    #gracias .gracias_contenedor .gracias_texto {
        font-size: 44px;
    }
}
