.title_service {
  font-weight: 600;
  margin-top: 100px;
  letter-spacing: 0px;
  font-size: 40px;
  line-height: 40px;
}

.paragraph_service {
  font-size: 11px;
  margin-top: 20px;
  margin-bottom: 42px;
}

.img_service img {
  width: 100%;
  margin-top: 56px;
}

.button_top_section{
  position: relative;
  z-index: 10;
}

@media (min-width: 1024px) {
  .title_service {
    margin-top: 50px;
    font-size: 60px;
    max-width: 600px;
    line-height: 60px;
  }

  .paragraph_service {
    width: 60%;
    font-size: 18px;
  }

  .img_service {
    display: flex;
    justify-content: end;
    transform: translateY(-90px);
  }

  .img_service img {
    max-width: 600px;
  }
}
