@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;800&display=swap');

* {
  margin: 0;
  padding: 0;
}

.max-width {
  max-width: 1200px;
  margin: 0 auto;
  width: 90%;
}

.App {
  margin: auto;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  min-height: 100vh;
}

.app-black {
  background: black;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Estilos del menú */
.menu_contenedor {
  position: relative;
  z-index: 10000;
}

.menu {
  /* opacity: 0; */
  width: 40%;
  align-items: center;
  /* animation: fadeIn 1s forwards 1s;    */
  padding-left: 15px;
  padding-bottom: 5px;
  padding-top: 15px;
  max-width: 200px;
}

.menu .close {
  position: fixed;
  color: white;
  top: 5%;
  left: 5%;
  cursor: pointer;
}

.name_page {
  cursor: pointer;
  text-decoration: none;
  font-size: 25px;
}

.color-negro {
  color: #221e1d;
}

.color-white .cls-1,
.color-white .cls-4,
.color-white .cls-3,
.color-white .cls-2 {
  stroke: white;
}

.name_agencia {
  font-size: 10px;
  padding-left: 10px;
}

.hamburguesa {
  position: fixed;
  left: 0;
  text-align: center;
  color: white;
  width: 50px;
  font-size: 30px;
  transition: 0.9s all;
  cursor: pointer;
  opacity: 0;
  animation: fadeIn 1s forwards 8s;
}

img.open_menu {
  width: 100%;
}

.hamburguesa .noShow {
  display: none;
}

.hamburguesa .show {
  display: block;
}

.animacion_hambur {
  animation: hamburguesa 0.4s forwards;
}

.menu_web {
  display: none;
  animation: fadeIn 1s forwards 1s;
}

.menus {
  background: #000000;
  position: fixed;
  height: 101vh;
  width: 100%;
  display: flex;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  top: -1px;
  left: 0;
  transition: 0.5s all;
  z-index: 1000;
}

.menu_inside {
  position: absolute;
  top: 0%;
  left: 0;
  width: 100%;
}

.contenedor_menu_inside {
  width: 40%;
  padding-left: 15px;
  padding-bottom: 5px;
  padding-top: 15px;
  max-width: 200px;
}

.menu_inside .name_page {
  cursor: pointer;
  text-decoration: none;
  color: white;
  font-size: 25px;
}

.menu_inside .name_agencia {
  color: white;
}

.menu_inside .close {
  position: fixed;
  left: 0;
  text-align: center;
  width: 50px;
  font-size: 30px;
  cursor: pointer;
}

.menu_inside .close span {
  display: block;
}

.linea_close_svg {
  fill: none;
  stroke: white;
  stroke-miterlimit: 10;
  stroke-dasharray: 15000.41943359375;
  stroke-dashoffset: 15000.41943359375;
  stroke-width: 2px;
  animation: cls_animation 20s linear forwards;
}

.span {
  transform: rotateY(0);
  animation: close 1s forwards;
}

.contenedor_menu {
  padding-left: 0px;
  transition: 1.5s all;
}

@media (min-width: 1024px) {
  .contenedor_menu {
    display: flex;
    justify-content: space-evenly;
    padding-right: 80px;
    width: 100%;
  }
}

.contenedor_menu li {
  list-style: none;
}

.contenedor_menu li .item_menu {
  list-style: none;
  color: white;
  text-decoration: none;
  font-size: 24px;
  margin-bottom: 25px;
  display: block;
}

.item_menu span {
  font-size: 10px;
  display: inline-block;
  transform: translate(0px, -8px);
  padding-right: 3px;
}

.bottom_menu {
  position: fixed;
  bottom: 5%;
  left: 5%;
  font-size: 10px;
  color: #ffffff;
}

/* Clases para animaciones */
.menus_show {
  opacity: 1;
  visibility: visible;
}

.contenedor_menu_show {
  padding-left: 40px;
}

.change_white {
  background: white;
  color: #221e1d;
  transition: 0.9s all;
}

.change_white2 {
  color: #221e1d;
  transition: 0.9s all;
}

.prueba {
  position: absolute;
  top: 0;
}

.scroll_none {
  overflow-y: hidden;
  overflow-x: hidden;
}

.menu-negro {
  background: black;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes close {
  0% {
    transform: rotateY(0);
  }
  100% {
    transform: rotateY(180deg);
  }
}

@keyframes hamburguesa {
  0% {
    transform: matrix(1, 0, 0, 0, 0, 0);
  }
  10% {
    transform: matrix(1, 0, 0, 0.1, 0, 0);
  }
  20% {
    transform: matrix(1, 0, 0, 0.2, 0, 0);
  }
  30% {
    transform: matrix(1, 0, 0, 0.3, 0, 0);
  }
  40% {
    transform: matrix(1, 0, 0, 0.4 0, 0);
  }
  50% {
    transform: matrix(1, 0, 0, 0.5, 0, 0);
  }
  60% {
    transform: matrix(1, 0, 0, 0.6, 0, 0);
  }
  70% {
    transform: matrix(1, 0, 0, 0.7, 0, 0);
  }
  80% {
    transform: matrix(1, 0, 0, 0.8, 0, 0);
  }
  90% {
    transform: matrix(1, 0, 0, 0.9, 0, 0);
  }
  100% {
    transform: matrix(1, 0, 0, 1, 0, 0);
  }
}

/* LOGO INDIO */
.logo svg {
  width: 100%;
}

.cls-1,
.cls-2,
.cls-3,
.cls-4 {
  fill: none;
  stroke: black;
  stroke-miterlimit: 10;
  stroke-dasharray: 15000.41943359375;
  stroke-dashoffset: 15000.41943359375;
  stroke-width: 10px;
  animation: cls_animation 20s linear forwards;
}

.menu_inside .contenedor_menu_inside .cls-1 {
  fill: none;
  stroke: white;
  stroke-miterlimit: 10;
}

.menu_inside .contenedor_menu_inside .cls-2 {
  fill: none;
  stroke: white;
  stroke-miterlimit: 10;
}

.menu_inside .contenedor_menu_inside .cls-3 {
  fill: none;
  stroke: white;
  stroke-miterlimit: 10;
}

.menu_inside .contenedor_menu_inside .cls-4 {
  fill: none;
  stroke: white;
  stroke-miterlimit: 10;
}

@keyframes cls_animation {
  to {
    stroke-dashoffset: 0;
  }
}

/* Hamburguesa */
.lineas_menu_svg {
  fill: none;
  stroke: white;
  stroke-miterlimit: 10;
  stroke-dasharray: 15000.41943359375;
  stroke-dashoffset: 15000.41943359375;
  stroke-width: 1px;
  animation: lineas_menu_svg 2s linear forwards;
}

@keyframes lineas_menu_svg {
  to {
    stroke-dashoffset: 0;
  }
}

/* Responsive solo para menú */
@media only screen and (min-width: 768px) {
  .contenedor_menu li .item_menu {
    list-style: none;
    color: white;
    text-decoration: none;
    font-size: 40px;
    margin-bottom: 25px;
    display: block;
    font-weight: 600;
  }
}

/* Estilos del footer */
.clearfix {
  overflow: auto;
  clear: both;
}
.footer {
  /* display: none; */
  padding: 130px 0 30px 0;
}

/* .block{
    display: block !important;
} */

.footer-black {
  background: black;
}

.footer .logo_indio_footer {
  max-width: 80px;
  width: 100%;
  margin: 0px auto;
  display: block;
  padding-bottom: 40px;
}

.footer .lineas_footer {
  width: 100%;
  margin: 0px auto;
  display: block;
  padding-bottom: 25px;
}

.footer-black .lineas_footer {
  filter: invert(1);
}

.footer .name_page {
  cursor: pointer;
  text-decoration: none;
  color: #221e1d;
  font-size: 25px;
  text-align: center;
  display: block;
  padding-bottom: 25px;
}

.menu_footer {
  width: 80%;
  margin: 0px auto;
  display: flex;
  justify-content: space-around;
  color: #a5a5a5;
  font-size: 10px;
}

.menu_footer div {
  cursor: pointer;
}

.footer_icons .link {
  text-decoration: none;
  color: black;
}

.footer-black .link {
  color: white !important;
}

.footer .politica_privacidad {
  text-align: center;
  font-size: 10px;
  color: #221e1d;
  padding-top: 30px;
}

img.movil {
  display: block !important;
}

img.desktop {
  display: none !important;
}

/* responsive para el footer */
@media only screen and (min-width: 768px) {
  .menu_footer {
    display: block;
    font-size: 12px;
    text-align: center;
  }

  .menu_footer div {
    padding: 5px 0;
  }

  img.movil {
    display: none !important;
  }

  img.desktop {
    display: block !important;
  }
}

/* ///////////////////////////////// */

.sub_fields {
  transform: translateY(-15px);
  position: relative;
  padding: 5px 0px 5px 60px;
}

.sub_fields :nth-child(1) {
  color: #ef7a90;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
}

.sub_fields :nth-child(1) span {
  font-size: 14px;
}

@media (min-width: 1024px) {
  .sub_fields {
    padding: 10px 0px 10px 200px;
  }
  .sub_fields :nth-child(1) {
    font-size: 22px;
  }
  .sub_fields :nth-child(1) span {
    font-size: 18px;
  }
  .container_sub_fields {
    margin-top: 50px;
  }
}

.button_one button {
  background: transparent linear-gradient(180deg, #ef7a906b 0%, #24748a7a 100%) 0% 0% no-repeat padding-box;
  color: #ffffff;
  border: 2px #ffffff solid;
  font-size: 12px;
  padding: 8px 40px;
  border-radius: 20px;
}

@media (min-width: 1024px) {
  .button_one button {
    font-size: 20px;
    padding: 10px 60px;
    border-radius: 30px;
    font-weight: bold;
  }
}

.button_two button {
  background-color: #ffffff;
  border: 1px #ef7a90 solid;
  color: black;
  font-size: 12px;
  padding: 8px 40px;
  font-weight: 1000px;
  border-radius: 20px;
}

@media (min-width: 1024px) {
  .button_two button {
    font-size: 20px;
    padding: 10px 60px;
    border-radius: 30px;
    font-weight: 700;
    cursor: pointer;
  }
}

.animated-element {
  opacity: 0;
}

.animate__animated.animate__backInRight {
  --animate-duration: 2s;
}

.animate__animated.animate__backInLeft {
  --animate-duration: 2s;
}

.animate__animated.animate__backInUp {
  --animate-duration: 2s;
}
.animate__animated.animate__fadeInDownBig {
  --animate-duration: 2s;
}

.container_services {
  overflow: hidden;
}

/* :root {
  --animate-duration: 5ms;
  --animate-delay: 0.9s;
} */

.font_bold {
  font-weight: 600;
}

.border_color {
  border: 1px solid transparent;
  border-image: linear-gradient(180deg, rgba(33, 117, 139, 1) 0%, rgba(236, 122, 144, 1) 100%);
  border-image-slice: 1;
}

.italic {
  font-style: italic;
}
